/**
 * Site header
 */

#logo-col img {
  margin-bottom: 15px;
}

#logo-col h1 {
  padding: 0;
  margin: 0;
}

#logo-col a {
  display: block;
  height: 46px;
}

#logo-col a span {
  display: none;
}
// #############################################################################
// ###   general stuff   #######################################################
// #############################################################################

body {
  background-color: #e6e6e6!important;
}

// #############################################################################
// ###   splash   ##############################################################
// #############################################################################
#splash{
  background-color: #2c5aa0;
  background-size: cover !important;

  h2 {
    color: white;
    padding-bottom: 0;
    font-family: sans-serif;
  }

  p {
    color: white;
    font-family: sans-serif;
  }

  img.img-rounded {
    background-color: white;
    padding: 6px;
    width: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 20px;
    float: left;
  }
}

// ###   splash sizes   ############
.splash-size-large {
  h2 {
    font-size: 40px !important;
    margin-top: 80px;
  }
  p {
    font-size: 20px;
    margin-bottom: 80px;
  }
}

.splash-size-small {
  h2 {
    font-size: 30px !important;
    margin-top: 60px;
  }
  p {
    font-size: 15px;
    margin-bottom: 60px;
  }
}

// ###   splash pages   ############
.splash-section-homepage {
  background-image: url('/static/img/titles/title_front.jpg') !important;
}

.splash-section-start {
  background-image: url('/static/img/titles/title_start.jpg') !important;
}

.splash-section-tools {
  background-image: url('/static/img/titles/title_tools.jpg') !important;
}

.splash-section-tech {
  background-image: url('/static/img/titles/title_tech.jpg') !important;
  background-position: right 60% !important;
}

.splash-section-deploy {
  background-image: url('/static/img/titles/title_deploy.jpg') !important;
  background-position: right 20% !important;
}

.splash-section-search, .splash-section-contribute {
  background-image: url('/static/img/titles/title_general.jpg') !important;
  background-position: right 60% !important;
}


// #############################################################################
// ###   banners   #############################################################
// #############################################################################
#front-nav {
  margin-top: 40px;
  margin-bottom: 40px;

  .panel {
    padding: 20px;
    text-align: left;
    min-height: 220px;
    margin-bottom: 0px;
  }

  img {
    height: 50px;
    margin-top: 10px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  h3 {
    padding-left: 0;
    padding-top: 15px!important;
    padding-bottom: 10px!important;
  }
}


.banner-contribute {
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: sans-serif;

  h2 {
    color: #4d4d4d;
    font-size: 30px!important;
    padding-bottom: 0;
    margin-top: 0px;
  }

  p {
    font-size: 15px;
  }

  a.btn-primary {
    color: white;
  }
}

.banner-fedora {
  background-image: url('/static/img/blue-pattern.jpg');
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: sans-serif;

  h2 {
    color: #eee;
    font-size: 30px!important;
    padding-bottom: 0;
    margin-top: 0px;
  }

  p {
    color: #eee;
    font-size: 15px;
  }

  a.btn-primary {
    color: white;
  }
}


div.content-menu {
  margin-top: 40px;
  margin-bottom: 40px;

  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);

  a {

  }

  a.active {
    background-color: #2c5aa0!important;
  }
}

.list-group-item {

  border: 0!important;
}
.nothing-hahahah {
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}

div.content-content {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;

  h1 {
    font-size: 30px!important;
  }

  h2 {
    font-size: 24px!important;
    padding-bottom: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #4d4d4d!important;
    font-family: sans-serif;
    padding-left: 0px;
  }

  :first-child {
    margin-top: 0px;
  }

  p {
    margin-left: 0px;
    color: #111;
  }

  img {
    object-fit: scale-down;
    width: 100%;
  }
}



.section-content {
  margin-bottom: 40px;
  margin-top: 10px;

  h2 {
    color: #4d4d4d;
    font-size: 30px!important;
    padding-bottom: 0;
    padding-top: 30px;
  }

  :first-child {
    margin-top: 0px;
  }
}



#menu-col, #search-col {
  margin-top: 12px;
}

#attribution a {
  color: white;
  font-size: .9em
}

#attribution {
  opacity: .3;
}



.title {
  height: 180px;
  margin-bottom: 30px;


  #page-title {
  padding-top: 20px;
  }

  img.img-rounded {
    background-color: white;
    padding: 6px;
    width: 100px;
    margin-top: 30px;
    margin-right: 20px;
    float: left;
  }

  h1 {
    margin-top: 60px;
  }
}

@media all and (min-width: $screen-sm-min) {
  .title{
    #page-description p {
    font-size: 14pt !important;
    height: 115px;
    display: table-cell !important;
    vertical-align: middle !important;
    padding-top: 35px;
    }
  }
}

@media all and (max-width: $screen-xs-max) {
  .title{
    img.img-circle{
      padding: 3px;
      width: 50px;
    }
    h1 {
      margin-top: 40px;
      font-size: 1.8em;
    }
    #page-description p {
    font-size: 1em !important;
    height: 70px;
    display: table-cell !important;
    padding-top: 15px;
    }
  }
}

/***
 * Site search
 */
.entries {
  position: absolute;
  top: 44px;
  right: 15px;
  background: white;
  color: black;
  border: 1px solid $grey-color-light;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 0;
  padding: 5px;
  z-index: 10;
  width: 288px;
}
.entries p {
  padding: 0;
  margin: 0;
}

/***
 * Site nav
 */

#top {
   padding-top: 20px;
}

#logo-col img {
  float: left;
}

#splash nav a, #splash {
  color: white;
}

#splash nav a {
  font-size: 1.2em;
}

#splash nav a:hover {
  text-decoration: underline;
  background-color: transparent !important;
}

body .nav li.active a,
body .nav li a:hover {
  background: none;
  text-decoration: underline;
}

.btn.glyphicon {
  margin-top: -2px !important;
}

#search {
  margin-top: 10px !important;
}

// Search box on the search page
#headline-search {
  width: 500px; margin-left: auto; margin-right: auto; margin-top: 60px; margin-bottom: 22px;
}



.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */

 footer {
   margin-top: 0px;
 }

.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

.footer {
    background-color: #e6e6e6;
    color: #777;
    font-size: 13px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
    font-family: "Open sans";

  .widget-title {
    color: #8d8d8d;
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }

  .widget-body {
    color: #777;
    font-size: 13px;
  }

   dd {
    line-height: 1.6;
    margin-left: 0;
  }

  a {
    color: #000 !important;
  }
}
/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

h3 {
  margin-top: 1.2em;
  margin-bottom: 1.6em;
}

h3 a, h3 a:visited {
  color: $fedora-blue;
  font-weight: semibold;
}



.headlines {
  width: 100% !important;
  margin-left: 0;
  margin-right: 0;
  //min-height: 240px;

  .headlines-body {
    padding: 32px 5px 44px 5px;
  }

  img {
    padding-top: 14px;
  }
}

.headlines#devassistant {
  background-image: url('/static/img/noisy_grid.png') !important;
  background-repeat: repeat;

  p,h2 {
	text-align: left;
  }
}

.container + .container {
  padding-bottom: 2em;
}

#blog-headlines {
  background-image: url('/static/img/footer_lodyas.png') !important;
  background-repeat: repeat;
  color: white;
  width: 100% !important;
  padding: 22px 0;
}

#blog-features,
#blog-headlines {
  h2 span {
    background-color: #3c6eb4 !important;
    padding: 0px 5px 2px;
    text-transform: uppercase;
    font-size: 45%;
    color: white;
    padding-bottom: 0px;
  }

  h2 {
  font-size: 2.2em !important;
  padding-bottom: 0px;
  }

  h3 {
    font-face: "Open Sans" !important;
    font-weight: 600 !important;
    font-size: 150%;
  }

  p {
    color: #a5a8aa;
  }

  p.byline {
    color: #6f7276;
  }
}

#blog-features {

  p {
    color: #6f7276;
  }

  p.byline {
    color: #60605b;
    font-size: 14pt;
    margin-top: -8px;
  }

  p.date {
    font-style: italic;
    color: #a5a8aa;
    margin-top: -8px;
  }
}


h2 {
  font-size: 1.5em !important;
  font-weight: 700;
  color: #888;
  padding-bottom: 14px;
}

.panel-link div.panel {

  min-height: 165px;

  h3 {
  font-size: 1.4em !important;
  font-weight: normal !important;
  color: #337ab7;
  display: table-cell !important;
  padding-top: 12px;
  padding-bottom: 10px;
  }

  p {
    color: #888;
    //margin-left: 10px;

  }

  p:hover {
    text-decoration: none;
  }

}

.panel-link:hover {
  text-decoration: none !important;

  h3 {
    //text-decoration: underline;
  }

  .panel-default {
    border-top-color: #337ab7;
    border-right-color: #337ab7;
    border-bottom-color: #337ab7;
    border-left-color: #337ab7;
  }
}

.rh-logo {
  width: 175px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.copy {
  margin-left: 40px;
}
.sponsor {
  margin-left: 40px;
}
